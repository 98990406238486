import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { Mail } from 'react-feather';
import { PokeMegCircle } from './svg/pokeMegIcons';
import { Menu, X, Question, Pin } from './svg/icons';

const Header = ({siteTitle}) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <header className="flex-none sticky top-0 bg-white shadow-lg-custom z-20">
        <div className="container flex flex-row justify-between items-center mx-auto p-4">
          <div role="navigation" className="cursor-pointer flex items-center text-4xl text-blue font-extrabold" onClick={()=>navigate("/")} aria-hidden="true">
            <div className="mr-2">
              <PokeMegCircle className="h-12" />
            </div>
            {siteTitle}
          </div>
          <div className="flex">
            <div className="2xl:hidden xl:hidden lg:hidden md:hidden" onClick={()=>setShow(true)} style={{ transition: "all .15s ease" }} aria-hidden="true">
              <Menu className="text-blue hover:text-opacity-40 h-12"/>
            </div>
            <div role="navigation" className="cursor-pointer flex flex-row sm:hidden xs:hidden px-4 text-blue hover:text-opacity-40" onClick={()=>navigate("/search")} aria-hidden="true">
              <Pin className="mr-2 h-6"/>
              Poke Finder
            </div>
            <div role="navigation" className="cursor-pointer flex flex-row sm:hidden xs:hidden px-4 text-blue hover:text-opacity-40" onClick={()=>navigate("/contact")} aria-hidden="true">
              <Mail className="mr-2"/>
              Contact Us
            </div>
            <div role="navigation" className="cursor-pointer flex flex-row sm:hidden xs:hidden px-4 text-blue hover:text-opacity-40" onClick={()=>navigate("/faq")} aria-hidden="true">
              <Question className="mr-2 h-6"/>
              FAQ
            </div>
          </div>
        </div>
      </header>
      {show ? (
        <div className="bg-black bg-opacity-25 items-top overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={()=>setShow(false)} aria-hidden="true">
          <header className="sticky top-0 bg-white shadow-lg">
            <div className="container flex flex-row justify-between items-center mx-auto py-4 px-4">
              <div role="navigation" className="cursor-pointer flex items-center text-4xl text-blue font-extrabold" onClick={()=>navigate("/")} aria-hidden="true">
                <div className="mr-2">
                  <PokeMegCircle className="h-12" />
                </div>
                {siteTitle}
              </div>
              <div className="flex">
                <div className="cursor-pointer 2xl:hidden xl:hidden lg:hidden md:hidden" onClick={()=>setShow(false)} aria-hidden="true">
                  <X className="text-blue hover:text-opacity-40 h-12"/>
                </div>
              </div>
            </div>
            <div className="container flex flex-col justify-between items-center text-xl mx-auto pb-5 px-2">
              <div className="bg-blue h-1 min-w-full mb-2">

              </div>
              <div role="navigation" className="cursor-pointer text-orange flex flex-row items-center hover:text-opacity-40 mt-2" onClick={()=>navigate("/search")} aria-hidden="true">
                <Pin className="mr-2 h-6"/>
                Find Your Poke Spot
              </div>
              <div role="navigation" className="cursor-pointer text-orange flex flex-row items-center hover:text-opacity-40 mt-2" onClick={()=>navigate("/contact")} aria-hidden="true">
                <Mail className="mr-2"/>
                Connect With Us
              </div>
              <div role="navigation" className="cursor-pointer text-orange flex flex-row items-center hover:text-opacity-40 mt-2" onClick={()=>navigate("/faq")} aria-hidden="true">
                <Question className="mr-2 w-7"/>
                Q's n A's
              </div>
            </div>
          </header>
        </div>
      ): null}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header