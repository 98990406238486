import React from "react"
import PropTypes from "prop-types"

import { Instagram, Mail } from 'react-feather';

const Footer = () => {
  return (
    <footer className="flex-none bg-orange shadow-top-lg mt-4">
        <div className="container text-white flex flex-row mx-auto justify-between items-center p-4">
            <div className="flex flex-col">
                <div className="flex text-white">Contact us about literally anything ever.</div>
                <div className="cursor-pointer flex hover:text-opacity-40 text-white mt-1">
                    <a className="flex flex-row items-center" href="https://www.instagram.com/pokepossi/">
                        <Instagram className="mr-2"/>
                        pokepossi
                    </a>
                </div>
                <div className="cursor-pointer flex hover:text-opacity-40 text-white mt-1">
                    <a className="flex flex-row items-center" href="mailto: pokepossi@gmail.com">
                        <Mail className="mr-2"/>
                        pokepossi@gmail.com
                    </a>
                </div>
            </div>
        </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer